<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import AppLink from "@/Components/Shared/link/AppLink.vue";
import AppTitle from "@/Components/Shared/title/AppTitle.vue";
import AppIcon from "@/Components/Shared/icon/AppIcon.vue";
import AppBadge from "@/Components/Shared/badge/AppBadge.vue";
import AppButton from "@/Components/Shared/button/AppButton.vue";
import AppImage from "@/Components/Shared/image/AppImage.vue";
import AppUserAvatar from "@/Components/Shared/user/AppUserAvatar.vue";
import AppButtonGroup from "@/Components/Shared/button/AppButtonGroup.vue";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "@/Components/Shared/tooltip";

type Props = {
    deck: object;
    hideAuthor?: boolean;
};
const { deck, hideAuthor = false } = defineProps<Props>();

// classes
const classes: AntlerClasses<Props> = {
    base: "flex flex-col items-center gap-2 border border-slate-200 rounded px-3 py-4 h-full text-center bg-white hover:no-underline hover:bg-slate-50    ",
};
const { aClass } = installAntlerComponent("card", {}, classes);
</script>

<template>
    <AppLink
        :class="aClass()"
        :href="deck.path"
    >
        <div v-if="deck.card">
            <AppImage
                :alt="deck.name"
                class="max-h-[100px] max-w-[80px]"
                :src="deck.card.image"
                placeholder
                variant="card"
            />
        </div>

        <div class="flex items-center gap-2 mt-auto">
            <AppTitle
                as="h3"
                size="xsmall"
                class="mt-auto line-clamp-2"
            >
                {{ deck.name }}
            </AppTitle>

            <AppIcon
                v-if="!deck.is_public"
                name="lock"
                color="gray"
            />
        </div>

        <AppUserAvatar
            v-if="deck.author && !hideAuthor"
            :user="deck.author"
            show-name
            avatar-size="xsmall"
            class="mb-2"
        />

        <div class="mt-2 flex gap-2 items-center">
            <AppButton
                v-if="deck.is_public"
                icon="comment"
                size="xxsmall"
                color="grayLight"
            >
                {{ deck.good_comments_count || deck.comments_count }}
            </AppButton>

            <AppBadge
                v-if="deck.game"
                :color="deck.game.slug"
            >
                {{ deck.game.slug }}
            </AppBadge>

            <TooltipProvider v-if="deck.has_play">
                <Tooltip>
                    <TooltipTrigger>
                        <AppBadge
                            icon="book"
                            color="primary"
                        />
                    </TooltipTrigger>
                    <TooltipContent>
                        Dit deck heeft een speelwijze
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>
    </AppLink>
</template>
