<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import { useAttrs } from "vue";
import AppDeckItem from "@/Components/App/decks/AppDeckItem.vue";

export type GridSize = "small" | "regular";

type Props = {
    decks: object[];
    size?: GridSize;
    hideAuthor?: boolean;
};
const { decks, size = "regular", hideAuthor = false } = defineProps<Props>();

defineOptions({
    inheritAttrs: false,
});

// classes
const classes: AntlerClasses<Props> = {
    base: "grid gap-4",
    variants: {
        size: {
            small: "grid-cols-1 sm:grid-cols-2 md:grid-cols-1",
            regular: "grid-cols-1 sm:grid-cols-2 md:grid-cols-3",
        },
    },
};
const attrs = useAttrs();
const { aClass } = installAntlerComponent(
    "deck-grid",
    { size },
    classes,
    attrs,
);
</script>

<template>
    <div :class="aClass()">
        <AppDeckItem
            v-for="deck in decks"
            :key="deck.id"
            :deck="deck"
            :hide-author="hideAuthor"
        />
    </div>
</template>
